import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default () => (
  <Layout>
    <h2>Connect with Teachers</h2>
    <div className="clear-block">
      <div id="node-50" className="node">


        <div className="content clear-block">
          <p>We have had many requests for information about teachers who are using the Animal Note™ method and are
            willing to take new students. In answer to this need we are setting up this page. If you would like to have
            your name and contact information added please <Link to="/contact">contact us</Link>.</p>
          <p>We sincerely hope this will be helpful to those seeking Animal Note™ teacher, but to the teachers as
            well.</p>
          <p>Mary Lofton<br/>
            <a href="mailto:Mary.lofton@lrsd.org">Mary.lofton@lrsd.org</a><br/>
            501-680-6287<br/>
            Little Rock, AR</p>
          <p>Diana Cobb<br/>
            <a href="mailto:carizmas@yahoo.com">carizmas@yahoo.com</a><br/>
            Scottsdale, AZ</p>
          <p>Diane Gwynn McWhorter<br/>
            Diane Gwynn's Studio of Music<br/>
            7570 S US1, Ste. #8<br/>
            Hypoluxo, FL. 33462<br/>
            561.586.1374<br/>
            <a href="http://www.dianegwynnsstudioofmusic.com"
               title="www.dianegwynnsstudioofmusic.com">www.dianegwynnsstudioofmusic.com</a></p>
          <p>Creative Groove Studio<br/>
            1200 Park Avenue<br/>
            Hoboken, NJ 07030<br/>
            Tel: 917 922 1061<br/>
            Email: <a href="mailto:Sally@CreativeGrooveStudio.com">Sally@CreativeGrooveStudio.com</a><br/>
            Web: <a href="http://www.CreativeGrooveStudio.com"
                    title="www.CreativeGrooveStudio.com">www.CreativeGrooveStudio.com</a></p>
          <p>Karen Hesterly Piano Studio<br/>
            912 West 8th St<br/>
            Weslaco, TX 78596<br/>
            956-968-5102<br/>
            <a href="http://www.learningmusician.com/Karen"
               title="http://www.learningmusician.com/Karen">http://www.learningmusician.com/Karen</a></p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
